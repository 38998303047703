import { useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { GET_AUTH_TOKEN } from "../../queries/spotify";

export const Callback = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const [spotifyAuthToken] = useMutation(GET_AUTH_TOKEN);
  const navigate = useNavigate();

  useEffect(() => {
    const cookies = new Cookies();
    if (searchParams.get("error") === "access_denied") {
      cookies.set("spotifyCallback", "failed");
    }
    if (searchParams.get("state") !== cookies.get("state")) {
      cookies.set("spotifyCallback", "failed");
    }
    spotifyAuthToken({ variables: { code: searchParams.get("code") } })
      .then((res) => {
        cookies.set("spotifyAuth", res.data.spotifyAuthToken);
        cookies.set("spotifyCallback", "success");
        cookies.remove("state");
        navigate("/");
      })
      .catch(() => {
        cookies.set("spotifyCallback", "failed");
      });
  }, [navigate, searchParams, spotifyAuthToken]);
  return <div />;
};
