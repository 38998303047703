import { useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import { REFRESH_AUTH_TOKEN } from "../../queries/spotify";
import { SpotifySignInButton } from "./SpotifySignInButton";

export const Index = (): JSX.Element => {
  const [spotifyRefreshToken] = useMutation(REFRESH_AUTH_TOKEN);
  const cookies = new Cookies();

  useEffect(() => {
    if (cookies.get("spotifyCallback") !== undefined) {
      handleSpotifyCallback();
    } else if (cookies.get("spotifyAuth") !== undefined) {
      refreshSpotifyToken();
    }
  }, []);

  // TODO can I break this out?
  const refreshSpotifyToken = (): void => {
    const auth = cookies.get("spotifyAuth");
    spotifyRefreshToken({ variables: { refreshToken: auth.refreshToken } }).then((res) => {
      auth.accessToken = res.data.spotifyRefreshToken.accessToken;
      auth.expiresIn = res.data.spotifyRefreshToken.expiresIn;
      cookies.set("spotifyAuth", auth);
      setTimeout(refreshSpotifyToken, auth.expiresIn * 1000);
    });
  };

  const handleSpotifyCallback = (): void => {
    if (cookies.get("spotifyCallback") === "failure") {
      cookies.remove("spotifyCallback");
      return;
    }
    if (cookies.get("spotifyCallback") === "success") {
      cookies.remove("spotifyCallback");
      setTimeout(refreshSpotifyToken, cookies.get("spotifyAuth").expiresIn * 1000);
    }
  };
  return (
    <div className="bg-blue-500">
      <SpotifySignInButton />
    </div>
  );
};
