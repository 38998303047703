import { gql } from "@apollo/client";

export const GET_AUTH_TOKEN = gql`
  mutation AuthToken($code: String!) {
    spotifyAuthToken(code: $code) {
      accessToken
      expiresIn
      refreshToken
    }
  }
`;

export const REFRESH_AUTH_TOKEN = gql`
  mutation AuthToken($refreshToken: String!) {
    spotifyRefreshToken(refreshToken: $refreshToken) {
      accessToken
      expiresIn
    }
  }
`;
