import React from "react";
import Cookies from "universal-cookie";
import { useRandString } from "../../lib/useRandString";

export const SpotifySignInButton = (): JSX.Element => {
  const clientID = "8d219ac9dc7d4fbfb39d1fb611bd5e46";
  const redirectURI = "http://localhost:3000/callback";
  const scope = "streaming user-read-private user-read-email";
  const state = useRandString(16);
  const params = new URLSearchParams({
    response_type: "code",
    client_id: clientID,
    scope,
    redirect_uri: redirectURI,
    state,
  });

  // TODO set secure cookie parameters
  const signIn = (): void => {
    const cookies = new Cookies();
    cookies.set("state", state);
    window.location.replace(`https://accounts.spotify.com/authorize?${params}`);
  };

  return (
    <button onClick={() => signIn()} type="button">
      Sign In
    </button>
  );
};
